<template>
  <div id="managementReviewReport">
    <el-dialog
      :title="managementReviewReportFormTitle"
      width="680px"
      :visible.sync="managementReviewReportDialogVisible"
      :close-on-click-modal="false"
      @close="managementReviewReportDialogClose"
    >
      <el-form
        ref="managementReviewReportFormRef"
        :model="managementReviewReportForm"
        :rules="managementReviewReportFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="评审时间" prop="reviewTime">
              <el-date-picker v-model="managementReviewReportForm.reviewTime" placeholder="请选择日期" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="主持人" prop="host">
              <el-input v-model="managementReviewReportForm.host" placeholder="请输入主持人" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="评审依据" prop="judgmentBasis">
              <el-input
                v-model="managementReviewReportForm.judgmentBasis"
                placeholder="请输入评审依据"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="评审目的" prop="purpose">
              <el-input
                v-model="managementReviewReportForm.purpose"
                placeholder="请输入评审目的"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="评审参加部门及人员" prop="deptPersonnel">
              <el-input
                v-model="managementReviewReportForm.deptPersonnel"
                placeholder="请输入评审参加部门及人员"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="评审内容" prop="content">
              <el-input
                v-model="managementReviewReportForm.content"
                placeholder="请输入评审内容"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="管理评审议程安排" prop="agenda">
              <el-input
                v-model="managementReviewReportForm.agenda"
                placeholder="请输入管理评审议程安排"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="管理评审的评价及评审结论" prop="evaluationConclusion">
              <el-input
                v-model="managementReviewReportForm.evaluationConclusion"
                placeholder="请输入管理评审的评价及评审结论"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="存在问题" prop="existingProblem">
              <el-input
                v-model="managementReviewReportForm.existingProblem"
                placeholder="请输入存在问题"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="针对问题采取的措施" prop="measure">
              <el-input
                v-model="managementReviewReportForm.measure"
                placeholder="请输入针对问题采取的措施"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="编辑人" prop="editor">
              <el-input v-model="managementReviewReportForm.editor" placeholder="请输入编辑人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编辑日期" prop="editDate">
              <el-date-picker v-model="managementReviewReportForm.editDate" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核人" prop="reviewer">
              <el-input v-model="managementReviewReportForm.reviewer" placeholder="请输入审核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审核时间" prop="reviewDate">
              <el-date-picker v-model="managementReviewReportForm.reviewDate" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准人" prop="approver">
              <el-input v-model="managementReviewReportForm.approver" placeholder="请输入批准人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准日期" prop="approveTime">
              <el-date-picker v-model="managementReviewReportForm.approveTime" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="managementReviewReportDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="managementReviewReportFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="评审主持人">
        <el-input v-model="searchForm.reviewHost" placeholder="请输入评审主持人" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="managementReviewReportPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="reviewTime" label="评审时间" />
      <el-table-column prop="host" label="主持人" />
      <el-table-column prop="judgmentBasis" label="评审依据" />
      <el-table-column prop="purpose" label="评审目的" />
      <el-table-column prop="deptPersonnel" label="评审参加部门及人员" />
      <el-table-column prop="content" label="评审内容" />
      <el-table-column prop="agenda" label="管理评审议程安排" />
      <el-table-column prop="evaluationConclusion" label="管理评审的评价及评审结论" />
      <el-table-column prop="existingProblem" label="存在问题" />
      <el-table-column prop="measure" label="针对问题采取的措施" />
      <el-table-column prop="editor" label="编制人" />
      <el-table-column prop="editDate" label="编制时间" />
      <el-table-column prop="reviewer" label="审核人" />
      <el-table-column prop="reviewDate" label="审核时间" />
      <el-table-column prop="approver" label="批准人" />
      <el-table-column prop="approveTime" label="批准时间" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="managementReviewReportPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addManagementReviewReport, deleteManagementReviewReport, updateManagementReviewReport, selectManagementReviewReportInfo, selectManagementReviewReportList } from '@/api/quality/managementReviewReport'

export default {
  data () {
    return {
      managementReviewReportDialogVisible: false,
      managementReviewReportFormTitle: '',
      managementReviewReportForm: {
        id: '',
        reviewTime: '',
        host: '',
        judgmentBasis: '',
        purpose: '',
        deptPersonnel: '',
        content: '',
        agenda: '',
        evaluationConclusion: '',
        existingProblem: '',
        measure: '',
        editor: '',
        editDate: '',
        reviewer: '',
        reviewDate: '',
        approver: '',
        approveTime: ''
      },
      managementReviewReportFormRules: {
        name: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}]
      },
      managementReviewReportPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      }
    }
  },
  created () {
    selectManagementReviewReportList(this.searchForm).then(res => {
      this.managementReviewReportPage = res
    })
  },
  methods: {
    managementReviewReportDialogClose () {
      this.$refs.managementReviewReportFormRef.resetFields()
      this.managementReviewReportForm.id = ''
    },
    managementReviewReportFormSubmit () {
      if (this.managementReviewReportFormTitle === '管理评审报告详情') {
        this.managementReviewReportDialogVisible = false
        return
      }
      this.$refs.managementReviewReportFormRef.validate(async valid => {
        if (valid) {
          if (this.managementReviewReportFormTitle === '新增管理评审报告') {
            await addManagementReviewReport(this.managementReviewReportForm)
          } else if (this.managementReviewReportFormTitle === '修改管理评审报告') {
            await updateManagementReviewReport(this.managementReviewReportForm)
          }
          this.managementReviewReportPage = await selectManagementReviewReportList(this.searchForm)
          this.managementReviewReportDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.managementReviewReportFormTitle = '新增管理评审报告'
      this.managementReviewReportDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteManagementReviewReport(row.id)
        if (this.managementReviewReportPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.managementReviewReportPage = await selectManagementReviewReportList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.managementReviewReportFormTitle = '修改管理评审报告'
      this.managementReviewReportDialogVisible = true
      this.selectManagementReviewReportInfoById(row.id)
    },
    handleInfo (index, row) {
      this.managementReviewReportFormTitle = '管理评审报告详情'
      this.managementReviewReportDialogVisible = true
      this.selectManagementReviewReportInfoById(row.id)
    },
    selectManagementReviewReportInfoById (id) {
      selectManagementReviewReportInfo(id).then(res => {
        this.managementReviewReportForm.id = res.id
        this.managementReviewReportForm.reviewTime = res.reviewTime
        this.managementReviewReportForm.host = res.host
        this.managementReviewReportForm.judgmentBasis = res.judgmentBasis
        this.managementReviewReportForm.purpose = res.purpose
        this.managementReviewReportForm.deptPersonnel = res.deptPersonnel
        this.managementReviewReportForm.content = res.content
        this.managementReviewReportForm.agenda = res.agenda
        this.managementReviewReportForm.evaluationConclusion = res.evaluationConclusion
        this.managementReviewReportForm.existingProblem = res.existingProblem
        this.managementReviewReportForm.measure = res.measure
        this.managementReviewReportForm.editor = res.editor
        this.managementReviewReportForm.editDate = res.editDate
        this.managementReviewReportForm.reviewer = res.reviewer
        this.managementReviewReportForm.reviewDate = res.reviewDate
        this.managementReviewReportForm.approver = res.approver
        this.managementReviewReportForm.approveTime = res.approveTime
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectManagementReviewReportList(this.searchForm).then(res => {
        this.managementReviewReportPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectManagementReviewReportList(this.searchForm).then(res => {
        this.managementReviewReportPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectManagementReviewReportList(this.searchForm).then(res => {
        this.managementReviewReportPage = res
      })
    },
    
  }
}
</script>

<style>
#managementReviewReport .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
