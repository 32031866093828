import axios from '@/common/axios'
import qs from 'qs'

export function addManagementReviewReport (data) {
  return axios({
    method: 'post',
    url: '/quality/reviewReport/add',
    data: qs.stringify(data)
  })
}

export function deleteManagementReviewReport (id) {
  return axios({
    method: 'delete',
    url: '/quality/reviewReport/delete/' + id
  })
}

export function updateManagementReviewReport (data) {
  return axios({
    method: 'put',
    url: '/quality/reviewReport/update',
    data: qs.stringify(data)
  })
}

export function selectManagementReviewReportInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/reviewReport/info/' + id
  })
}

export function selectManagementReviewReportList (query) {
  return axios({
    method: 'get',
    url: '/quality/reviewReport/list',
    params: query
  })
}
